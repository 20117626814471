import BasePlugin from '../BasePlugin'

export default class ExportScenarioCognosByInvestmentBudgetFromPlanningDashboard extends BasePlugin {
  async execute () {
    // this.context.$loading()
    let me = this
    let solution = this.context.getModel().solution
    let inSolution = this.context.getModel().inSolution
    if (solution === undefined) {
      this.context.$msgbox({
        type: 'info',
        message: 'Необходимо выбрать решение'
      })
      return
    }
    let address = `${this.context.$config.api}/registryservice/plugins/execute/ExportScenarioInvestmentBudgetFromPlanningDashboardCommand`
    this.context.$http({
      method: 'post',
      url: address,
      responseType: 'arraybuffer',
      data: { solution: solution, in_solution: inSolution, 'async': 'true' }
    }).then(function (response2) {
      me.context.$msgbox.close()
      me.context.$msgbox({
        type: 'info',
        message: 'Выгрузка шаблона поставлена в очередь. По завершении получите уведомление'
      })
    })
  }
}
